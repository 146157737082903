<template>
  <el-config-provider :locale="locale">
    <router-view />
  </el-config-provider>
</template>

<script>
  import { ElConfigProvider } from 'element-plus';
  import zhCn from 'element-plus/lib/locale/lang/zh-cn';
  export default {
    name: 'App',
    components: {
      [ElConfigProvider.name]: ElConfigProvider,
    },

    setup() {
      let locale = zhCn
      return {
        locale
      }
    }
  }
</script>

<style lang="scss">
  @import "./style/reset.scss";
  @import "./style/base.scss";

  //放大镜位置
  .mouse-cover-canvas {
    position: absolute;
    top: 173px !important;
    left: 740px !important;
  }
</style>